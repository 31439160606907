import { PATH, request, setToken } from "components/shared/Api";
import React, { useEffect, useRef, useState } from "react";
import {
  appleLogin,
  kakaoLogin,
  naverLogin,
} from "components/shared/SocialLogin";

import AlertModal from "components/ui/modal/AlertModal";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [receviedToken, setReceviedToken] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [code, setCode] = useState("");
  const idInputRef = useRef(null);

  //로그인 api
  const handleLoginCheck = async () => {
    try {
      const response = await request(PATH.로그인, {
        userId: userId,
        userPw: userPw,
        type: "service",
      });
      if (response.data.code && response.data.code !== "0000") {
        setCode(response.data.code);
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else if (response.data.code === "0000") {
        setReceviedToken(response.data.accessToken);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserIdReset = () => {
    setUserId("");
  };

  const handleUserPwReset = () => {
    setUserPw("");
  };

  // const pageChange = (page) => {
  //   if (window.ReactNativeWebView) {
  //     const message = { navigate: page() };
  //     window.ReactNativeWebView.postMessage(JSON.stringify(message));
  //   }
  // };

  useEffect(() => {
    if (receviedToken) {
      setToken(receviedToken);
    }
    console.log(
      !receviedToken
        ? "Login Token::::: " + "로그인 하지 않았습니다. 받은 토큰이 없습니다."
        : "Login Token::::: " + receviedToken,
    );
  }, [receviedToken]);

  return (
    <React.Fragment>
      {isAlert === true ? (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            if (code === "B900") {
              idInputRef.current.focus();
            }
          }}
        />
      ) : null}
      <div className="wrapper login">
        <div className="content pt60 pb80">
          <div className="title-box">
            <h2>
              관리비 정보를 한번에
              <br />
              <span>
                BUILDING <strong>CAS</strong>
              </span>
            </h2>
          </div>

          <div className="form-box-wrap mb80">
            <div className="form-box">
              <div className="tf-box-wrap mb20">
                <div className="tf-box">
                  <label htmlFor="id-login">아이디 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="id-login"
                        className="tf"
                        placeholder="아이디 입력"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        maxLength={20}
                        ref={idInputRef}
                      />
                      {userId ? (
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={handleUserIdReset}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap mb24">
                <div className="tf-box">
                  <label htmlFor="phone-login">비밀번호 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="phone-login"
                        className="tf"
                        placeholder="비밀번호 입력"
                        value={userPw}
                        onChange={(e) => setUserPw(e.target.value)}
                        maxLength={20}
                      />
                      {userPw ? (
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={handleUserPwReset}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-box mt28">
                <button
                  type="button"
                  className="btn1 main-color type2"
                  onClick={handleLoginCheck}
                >
                  로그인
                </button>
                <p className="flex-center mt18">
                  <button type="button" onClick={() => navigate("/find")}>
                    로그인 정보를 잊으셨나요?
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="social-login-box mb48">
            <p className="tit">간편 로그인</p>
            <div className="box flex-center gap20">
              <button
                type="button"
                className="naver"
                onClick={() => {
                  naverLogin();
                  // pageChange(naverLogin);
                }}
              />
              <button
                type="button"
                className="kakao"
                onClick={() => {
                  kakaoLogin();
                }}
              />
              <button
                type="button"
                className="apple"
                onClick={() => {
                  appleLogin();
                }}
              />
            </div>
          </div>

          <p className="flex-center gap16" style={{ margin: "0 auto" }}>
            아직 회원이 아니신가요?
            <button
              type="button"
              onClick={() => {
                navigate("/join");
              }}
            >
              회원가입
            </button>
          </p>

          <div className="f-info">
            본사 : 경기도 하남시 미사강변중앙로31번길 15, 917호(풍산동,
            희가로프리미어)
            <br />
            대표이사 : 윤재혁 사업자 번호 : 175-87-01546
            <br />
            통신판매 번호 : 제 2023-경기하남-1216
            <br />
            문의 : 02-6949-3662
            <br />
            이메일 : shdata001@sh-data.co.kr
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
